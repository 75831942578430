<template>
    <import-dialog
      ref="importDialogRef"
      title="批量导入学员"
      :ifPass="false"
      accept=".xls,.xlsx,.XLS,.XLSX"
      :ifshow-upload="$zwxBase.verifyIsBlank(staffinfoImportDialog.annexName)"
      :size="5 * 1024 * 1024"
      sizeInfo="5M"
      :action="api + '/training/platform/importTrainingList-0'"
      :paramData="staffinfoImportDialog.paramData"
      :errorName="staffinfoImportDialog.errorName"
      :errorPath="staffinfoImportDialog.errorPath"
      :errorMsg="staffinfoImportDialog.errorMsg"
      @beforeUpload="fileBeforeUpload"
      @tempDownload="tempDownload"
      @deletionFile="deletionFile"
      @fileSubmitSuccess="fileSubmitSuccess"
      @fileSubmitError="fileSubmitError"
      @change="clickFileUpload"
      @determine="staffImportDialogDetermine"
      @cancel="staffImportDialogCancel"
      @close="staffImportDialogClose"
      @updateSuccess="fileSubmitSuccess"
      :style="{
        fontWeight: 'normal',
      }"
    ></import-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        api: this.$store.state.api,
        file: this.$store.state.file,
        users: this.$store.state.users,
        staffinfoImportDialog: {
          annexName: '',
          exportErrorUid: '',
          errorName: '',
          errorPath: '',
          errorMsg: '',
          paramData: {
            tokenUid: this.$store.state.users.uid,
            activityUid: this.$route.params.row.uuid,
          },
        },
      }
    },
    methods: {
      show(bl, ifAll) {
        this.staffinfoImportDialog.paramData.ifAll = ifAll
        this.$refs.importDialogRef.show(bl)
      },
  
      fileBeforeUpload(value) {
        if (value === false) {
          // this.staffinfoImportDialog.determineBtnDisabled = true
        }
      },
      /**
       * 导入模板下载
       * */
      tempDownload() {
        let data = {
        templateSort: 14,
      }
      this.$system.get(
        this.api + '/oh/repository/getFileTemplateByCode-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$emit('loading', true)
            this.$system.downloadUrlFile('培训学员导入模板', this.$store.state.file + data.filePath)
            this.$emit('loading', false)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
      },
      /**
       * 删除导入文件
       * */
      deletionFile() {
        Object.assign(this.staffinfoImportDialog, {
          annexName: '',
          exportErrorUid: '',
          errorPath: '',
        })
      },
      fileSubmitSuccess(data) {
        // this.$emit('loading', false)
        if (data.type === '00') {
          // const result = data.result
          // Object.assign(this.staffinfoImportDialog, {
          //   exportErrorUid: result.exportErrorUid || '',
          //   errorName: result.errorName || '',
          //   errorPath: result.errorPath || '',
          // })
          this.$emit('success')
        } else {
          this.$system.notify('错误', data.mess, 'error')
          this.staffinfoImportDialog.exportErrorUid = data.exportErrorUid || ''
          this.staffinfoImportDialog.errorPath = data.errorPath || ''
        }
      },
      fileSubmitError(data = {}) {
        // this.$emit('loading', false)
        this.$system.notify('错误', data.mess || '网络连接失败', 'error')
        this.staffinfoImportDialog.exportErrorUid = data.exportErrorUid || ''
        this.staffinfoImportDialog.errorPath = data.errorPath || ''
      },
      /**
       * 上传文件
       * */
      clickFileUpload(showList) {
        showList.forEach(item => {
          let flag = this.staffinfoImportDialog.annexName === item
          if (flag) {
            return
          }
          this.staffinfoImportDialog.annexName = item
        })
        if (this.$zwxBase.verifyIsNotBlank(this.staffinfoImportDialog.annexName)) {
          this.staffinfoImportDialog.errorMsg = ''
        }
      },
      /**
       * 导入弹框确认
       * */
      staffImportDialogDetermine() {
        if (this.$zwxBase.verifyIsBlank(this.staffinfoImportDialog.annexName)) {
          this.staffinfoImportDialog.errorMsg = '请上传文件'
          return
        } else {
          this.staffinfoImportDialog.errorMsg = ''
        }
        // this.$emit('loading', true)
        // this.$system.postJson(
        //   this.api + '/zky/training/staffInfoImportUid-1',
        //   null,
        //   true,
        //   true,
        //   (data) => {
        //     if (data.type === '00') {
        //       this.staffinfoImportDialog.paramData.uuid = data.uid
        //       this.staffinfoImportDialog.paramData.passWord = this.$refs.importDialogRef.mainForm.passWord
        //     } else {
        //       this.$system.notify('错误', data.mess, 'error')
        //     }
        //   },
        //   (data) => {
        //     this.$emit('loading', false)
        //     this.$system.notify('错误', data.mess, 'error')
        //   }
        // )
        this.$refs.importDialogRef.fileSubmit()
      },
      /**
       * 导入弹框关闭
       * */
      staffImportDialogClose() {
        this.$refs.importDialogRef.mainForm.passWord = ''
        Object.assign(this.staffinfoImportDialog, {
          annexName: '',
          uid: '',
          exportErrorUid: '',
          errorPath: '',
          errorMsg: '',
        })
        this.staffinfoImportDialog.paramData.unitId = ''
        this.staffinfoImportDialog.paramData.uuid = ''
        this.$emit('refresh')
      },
      /**
       * 导入弹框取消
       * */
      staffImportDialogCancel() {
        this.$refs.importDialogRef.show(false)
      },
    },
  }
  </script>
  