var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ms-training-management-detail" },
    [
      _c("base-tab", {
        attrs: { titles: _vm.tabTitles },
        on: { changeTab: _vm.activeChange },
        model: {
          value: _vm.activeName,
          callback: function($$v) {
            _vm.activeName = $$v
          },
          expression: "activeName"
        }
      }),
      _vm.activeName == 1
        ? _c(
            "div",
            [
              _c("div", { staticClass: "detail-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.trainingActivityListForm.trainingActivityName) +
                    " "
                )
              ]),
              _c("div", { staticClass: "base-info" }, [
                _c("div", { staticClass: "detail-row" }, [
                  _c("div", { staticClass: "detail-item" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "detail-info-txt" }, [
                      _vm._v(_vm._s(_vm.userInfo.fkByUnitId.unitName))
                    ])
                  ]),
                  _c("div", { staticClass: "detail-item" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "detail-info-txt" }, [
                      _vm._v(
                        _vm._s(
                          _vm.trainingActivityListForm.leastNum
                            ? _vm.trainingActivityListForm.leastNum + "人"
                            : "0人"
                        )
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "detail-row" }, [
                  _c("div", { staticClass: "detail-item" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "detail-info-txt" }, [
                      _vm._v(
                        _vm._s(_vm.trainingActivityListForm.registStartTime) +
                          " 至 " +
                          _vm._s(_vm.trainingActivityListForm.registEndTime)
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "detail-item" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "detail-info-txt" }, [
                      _vm._v(
                        _vm._s(
                          _vm.trainingActivityListForm.mostNum
                            ? _vm.trainingActivityListForm.mostNum + "人"
                            : "0人"
                        )
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "detail-row" }, [
                  _vm.trainingActivityListForm.ifOnLineCourse
                    ? _c("div", { staticClass: "detail-item" }, [
                        _vm._m(4),
                        _c("div", { staticClass: "detail-info-txt" }, [
                          _vm._v(
                            _vm._s(
                              _vm.trainingActivityListForm.courseStartTime
                            ) +
                              " 至 " +
                              _vm._s(_vm.trainingActivityListForm.courseEndTime)
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.trainingActivityListForm.ifOffLineTraining
                    ? _c("div", { staticClass: "detail-item" }, [
                        _vm._m(5),
                        _c("div", { staticClass: "detail-info-txt" }, [
                          _vm._v(
                            _vm._s(
                              _vm.trainingActivityListForm.offLineStartTime
                            ) +
                              " 至 " +
                              _vm._s(
                                _vm.trainingActivityListForm.offLineEndTime
                              )
                          )
                        ])
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "detail-row" }, [
                  _vm.trainingActivityListForm.ifOffLineTraining
                    ? _c("div", { staticClass: "detail-item" }, [
                        _vm._m(6),
                        _c("div", { staticClass: "detail-info-txt" }, [
                          _vm._v(
                            _vm._s(
                              _vm.trainingActivityListForm.offLineTrainingAddr
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.trainingActivityListForm.examType == 2
                    ? _c("div", { staticClass: "detail-item" }, [
                        _vm._m(7),
                        _c("div", { staticClass: "detail-info-txt" }, [
                          _vm._v(_vm._s(_vm.trainingActivityListForm.examAddr))
                        ])
                      ])
                    : _vm._e()
                ])
              ]),
              _c("base-headline", {
                staticClass: "no-bottom",
                attrs: { title: "课程配置" }
              }),
              _c(
                "el-table",
                {
                  staticClass: "zwx-table",
                  attrs: {
                    "row-key": "uuid",
                    data: _vm.trainingActivityListForm.courseExpList,
                    stripe: "",
                    "tooltip-effect": "light"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "traineeTypeName",
                      label: "学员类型",
                      width: "200",
                      "header-align": "center",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "trainingTypeName",
                      label: "培训类型",
                      width: "100",
                      "header-align": "center",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "courseName",
                      label: "线上课程选择",
                      "min-width": "220",
                      "header-align": "center",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "onLineClassHoure",
                      label: "线上课程课时",
                      width: "120",
                      "header-align": "center",
                      align: "center"
                    }
                  }),
                  _vm.trainingActivityListForm.ifOffLineTraining
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "offLineClassHoure",
                          label: "线下培训课时",
                          width: "120",
                          "header-align": "center",
                          align: "center"
                        }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "registType",
                      label: "报名类型",
                      width: "120",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  scope.row.registType == 1 ? "非公开" : "公开"
                                )
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1700809265
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "expiryDate",
                      label: "报名价格（元）",
                      width: "140",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.registType == 1
                                ? _c("span", [_vm._v("-")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        Number(scope.row.registPrice).toFixed(2)
                                      )
                                    )
                                  ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1821178378
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "courseName",
                      label: "操作",
                      width: "100",
                      "header-align": "center",
                      align: "left"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass: "zwx-button zwx-button-text-26",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showCourseInfo(scope.row)
                                    }
                                  }
                                },
                                [
                                  _vm._v(" 课程详情 "),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-arrow-right el-icon--right"
                                  })
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2895450409
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.activeName == 2
        ? _c("div", [
            _c(
              "div",
              { staticClass: "student-management" },
              [
                _c(
                  "el-form",
                  {
                    key: "studentManagementForm",
                    ref: "studentManagementFormRef",
                    staticClass: "zwx-form",
                    attrs: {
                      model: _vm.studentManagementForm,
                      "label-position": "right",
                      "label-width": "100px"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "行政区划：" } },
                      [
                        _c("zwx-select-area", {
                          ref: "bizZoneArea1",
                          staticClass: "cascader-area-biz-zone",
                          attrs: {
                            zoneCode12From:
                              _vm.studentManagementForm.busZoneCode,
                            zoneTypeMax: "0",
                            zoneTypeMin: "4",
                            showFullName: false,
                            clearable: false
                          },
                          on: { change: _vm.bizZoneChange1 }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属单位：" } },
                      [
                        _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "200px !important" },
                          attrs: {
                            placeholder: "",
                            clearable: "",
                            maxlength: "50"
                          },
                          model: {
                            value: _vm.studentManagementForm.unitName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.studentManagementForm,
                                "unitName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "studentManagementForm.unitName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "学员姓名：" } },
                      [
                        _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "200px !important" },
                          attrs: {
                            placeholder: "",
                            clearable: "",
                            maxlength: "50"
                          },
                          model: {
                            value: _vm.studentManagementForm.userName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.studentManagementForm,
                                "userName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "studentManagementForm.userName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "学员类别：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "zwx-select zwx-select-student",
                                staticStyle: { width: "200px !important" },
                                attrs: {
                                  placeholder: "请选择",
                                  "popper-class": "zwx-select-popper",
                                  clearable: ""
                                },
                                on: { change: _vm.resetFilterOptions },
                                model: {
                                  value: _vm.studentManagementForm.traineeType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.studentManagementForm,
                                      "traineeType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "studentManagementForm.traineeType"
                                }
                              },
                              _vm._l(_vm.targetPopulationOptions, function(
                                item
                              ) {
                                return _c("el-option", {
                                  key: item.codeNo,
                                  attrs: {
                                    label: item.codeName,
                                    value: item.codeNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "培训类型：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "zwx-select zwx-select-student",
                                staticStyle: { width: "200px !important" },
                                attrs: {
                                  placeholder: "请选择",
                                  "popper-class": "zwx-select-popper",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.studentManagementForm.trainingType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.studentManagementForm,
                                      "trainingType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "studentManagementForm.trainingType"
                                }
                              },
                              _vm._l(_vm.trainingTypeFilterOptions, function(
                                item
                              ) {
                                return _c("el-option", {
                                  key: item.codeNo,
                                  attrs: {
                                    label: item.codeName,
                                    value: item.codeNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "报名类型：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "zwx-select zwx-select-student",
                                staticStyle: { width: "200px !important" },
                                attrs: {
                                  placeholder: "请选择",
                                  "popper-class": "zwx-select-popper"
                                },
                                model: {
                                  value: _vm.studentManagementForm.registWay,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.studentManagementForm,
                                      "registWay",
                                      $$v
                                    )
                                  },
                                  expression: "studentManagementForm.registWay"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "不限", value: "" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "自主报名", value: 1 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "管理员报名", value: 2 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "签到情况：" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "zwx-select zwx-select-student",
                            staticStyle: { width: "200px !important" },
                            attrs: {
                              placeholder: "请选择",
                              "popper-class": "zwx-select-popper"
                            },
                            model: {
                              value: _vm.studentManagementForm.ifSignIn,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.studentManagementForm,
                                  "ifSignIn",
                                  $$v
                                )
                              },
                              expression: "studentManagementForm.ifSignIn"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "不限", value: "" }
                            }),
                            _c("el-option", {
                              attrs: { label: "未签到", value: 0 }
                            }),
                            _c("el-option", {
                              attrs: { label: "线上签到", value: 1 }
                            }),
                            _c("el-option", {
                              attrs: { label: "补签", value: 2 }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "考试情况：" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "zwx-select zwx-select-student",
                            staticStyle: { width: "200px !important" },
                            attrs: {
                              placeholder: "请选择",
                              "popper-class": "zwx-select-popper"
                            },
                            model: {
                              value: _vm.studentManagementForm.examResultInt,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.studentManagementForm,
                                  "examResultInt",
                                  $$v
                                )
                              },
                              expression: "studentManagementForm.examResultInt"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "不限", value: "" }
                            }),
                            _c("el-option", {
                              attrs: { label: "学习未开始", value: 0 }
                            }),
                            _c("el-option", {
                              attrs: { label: "学习未完成", value: 1 }
                            }),
                            _c("el-option", {
                              attrs: { label: "学习已完成", value: 2 }
                            }),
                            _c("el-option", {
                              attrs: { label: "待考试", value: 3 }
                            }),
                            _c("el-option", {
                              attrs: { label: "考试通过", value: 5 }
                            }),
                            _c("el-option", {
                              attrs: { label: "考试不通过", value: 4 }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "线上课程进度：" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "zwx-select zwx-select-student",
                            staticStyle: { width: "200px !important" },
                            attrs: {
                              placeholder: "请选择",
                              "popper-class": "zwx-select-popper"
                            },
                            model: {
                              value: _vm.studentManagementForm.studyState,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.studentManagementForm,
                                  "studyState",
                                  $$v
                                )
                              },
                              expression: "studentManagementForm.studyState"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "不限", value: "" }
                            }),
                            _c("el-option", {
                              attrs: { label: "未开始", value: 0 }
                            }),
                            _c("el-option", {
                              attrs: { label: "进行中", value: 1 }
                            }),
                            _c("el-option", {
                              attrs: { label: "已完成", value: 2 }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "independent-button-row" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-icontext-28",
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: {
                          click: function($event) {
                            return _vm.queryTrainingCourse(1)
                          }
                        }
                      },
                      [_vm._v("查询")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticClass: "zwx-table",
                    attrs: {
                      "row-key": "uuid",
                      data: _vm.studenTableList,
                      border: "",
                      stripe: "",
                      "tooltip-effect": "light"
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "unitName",
                        label: "所属单位",
                        "min-width": "260",
                        "header-align": "center",
                        align: "left"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "userName",
                        label: "姓名",
                        width: "120",
                        "header-align": "center",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "traineeTypeName",
                        label: "学员类别",
                        width: "160",
                        "header-align": "center",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "trainingTypeName",
                        label: "培训类型",
                        width: "100",
                        "header-align": "center",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mobileTel",
                        label: "手机号",
                        width: "120",
                        "header-align": "center",
                        align: "center"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$zwxSm.sm4JsDecrypt(
                                        scope.row.mobileTel
                                      )
                                    )
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        326311269
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cardTypeName",
                        label: "证件类型",
                        width: "150",
                        "header-align": "center",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cardNo",
                        label: "证件号",
                        width: "180",
                        "header-align": "center",
                        align: "center"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$zwxSm.sm4JsDecrypt(scope.row.cardNo)
                                    )
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2970309805
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ifSignIn",
                        label: "线下培训签到",
                        width: "140",
                        "header-align": "center",
                        align: "center"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.ifSignIn == 0
                                  ? _c("span", [_vm._v("未签到")])
                                  : _vm._e(),
                                scope.row.ifSignIn == 1
                                  ? _c("span", [_vm._v("线上签到")])
                                  : _vm._e(),
                                scope.row.ifSignIn == 2
                                  ? _c("span", [_vm._v("补签")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        964660024
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "trainingActivityName",
                        label: "线上课程进度",
                        width: "120",
                        "header-align": "center",
                        align: "center"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row.alreadyClassHoure) +
                                      "/" +
                                      _vm._s(scope.row.allRequiredClassHoure)
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        38638922
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "examResultStr",
                        label: "考试情况",
                        width: "120",
                        "header-align": "center",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "registWay",
                        label: "报名类型",
                        "min-width": "80",
                        "header-align": "center",
                        align: "center"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.registWay == 1
                                  ? _c("span", [_vm._v("自主报名")])
                                  : _vm._e(),
                                scope.row.registWay == 2
                                  ? _c("span", [_vm._v("管理员报名")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1797131737
                      )
                    })
                  ],
                  1
                ),
                _c("base-pagination", {
                  attrs: {
                    parentPage: _vm.studentManagementForm.currentPage,
                    pageSize: _vm.studentManagementForm.pageSize,
                    total: Number(_vm.studentManagementForm.total)
                  },
                  on: { currentChange: _vm.queryTrainingCourse }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "base-dialog",
        {
          ref: "coursewareDialogRef",
          staticClass: "courseware-dialog",
          attrs: {
            title: "课程明细",
            pagination: true,
            ifCancel: false,
            ifDetermine: false,
            total: Number(_vm.trainingCourseListFormExp.materialTotal),
            pageSize: 7
          },
          on: {
            cancel: _vm.coursewareDialogCancel,
            close: _vm.coursewareDialogClose,
            currentPage: _vm.coursewareDialogCurrent
          }
        },
        [
          _c(
            "el-table",
            {
              ref: "teachingMaterialTable",
              staticClass: "zwx-table",
              attrs: {
                "row-key": "uuid",
                data: _vm.trainingActivityListFormExp.teachingMaterialList,
                stripe: "",
                "tooltip-effect": "light",
                "max-height": "310px"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "coursewareTitle",
                  label: "课程名称",
                  "min-width": "200",
                  "header-align": "center",
                  align: "left",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "classHour",
                  label: "学时",
                  "header-align": "center",
                  align: "center",
                  width: "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.coursewareType == 1 ||
                        scope.row.coursewareType == 3
                          ? _c("span", [_vm._v("-")])
                          : _c("span", [_vm._v(_vm._s(scope.row.classHour))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "requiredCourse",
                  label: "性质",
                  "header-align": "center",
                  align: "center",
                  width: "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.coursewareType == 1 ||
                        scope.row.coursewareType == 3
                          ? _c("span", [_vm._v("-")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.requiredCourse ? "必修" : "选修"
                                )
                              )
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("div", { staticClass: "classHour" }, [
            _vm._v(" 已选课程合计：必修 "),
            _c("span", [
              _vm._v(
                _vm._s(this.trainingActivityListFormExp.requiredCourseHours)
              )
            ]),
            _vm._v(" 学时，选修 "),
            _c("span", [
              _vm._v(
                _vm._s(this.trainingActivityListFormExp.noRquiredCourseHours)
              )
            ]),
            _vm._v(" 学时 ")
          ])
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "themeActivityListAddDialogRef",
          staticClass: "theme-activity-list-add-dialog",
          attrs: { title: "添加学员" },
          on: {
            determine: _vm.determineAddDialog,
            cancel: _vm.cancelAddDialog,
            close: _vm.closeAddDialog
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "themeActivityListAddDialogForm",
              staticClass: "zwx-form",
              attrs: {
                model: _vm.themeActivityListAddDialogForm,
                rules: _vm.themeActivityListAddDialogFormRules,
                "label-position": "right"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c("div", { staticClass: "flex-row" }, [
                _c("div", { staticClass: "left-info" }, [
                  _c("div", { staticClass: "tips flex-row" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "16px",
                          height: "16px",
                          "font-size": "12px",
                          background: "#ff8800",
                          color: "#fff",
                          "border-radius": "50%",
                          "text-align": "center",
                          "margin-right": "8px"
                        }
                      },
                      [_vm._v("!")]
                    ),
                    _c("div", [_vm._v("操作提示")])
                  ]),
                  _c("div", { staticClass: "title-text flex-row" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "22px",
                          height: "22px",
                          "font-size": "16px",
                          background: "#4a75f5",
                          color: "#fff",
                          "border-radius": "50%",
                          "text-align": "center",
                          "margin-right": "8px"
                        }
                      },
                      [_vm._v("1")]
                    ),
                    _c("div", [_vm._v("通过证件号码检索学员档案")])
                  ]),
                  _c("div", { staticClass: "info-box flex-column-center" }, [
                    _c("div", [
                      _vm._v("1、检索结果有记录，选中后自动填充学员信息")
                    ]),
                    _c("div", { staticStyle: { "margin-top": "12px" } }, [
                      _vm._v("2、检索结果无记录，手动输入学员信息")
                    ])
                  ]),
                  _c("div", { staticClass: "title-text flex-row" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "22px",
                          height: "22px",
                          "font-size": "16px",
                          background: "#4a75f5",
                          color: "#fff",
                          "border-radius": "50%",
                          "text-align": "center",
                          "margin-right": "8px"
                        }
                      },
                      [_vm._v("2")]
                    ),
                    _c("div", [_vm._v("通过所属单位检索单位信息")])
                  ]),
                  _c("div", { staticClass: "info-box flex-column-center" }, [
                    _c("div", [
                      _vm._v("1、检索结果有记录，选中后自动填充单位信息")
                    ]),
                    _c("div", { staticStyle: { "margin-top": "12px" } }, [
                      _vm._v("2、检索结果无记录，手动输入单位信息")
                    ])
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticClass: "right-form",
                    staticStyle: { "margin-top": "20px" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "zwx-circle-text zwx-circle-text:before zwx-circle-normal zwx-circle-normal:before",
                        staticStyle: {
                          color: "#505b89 !important",
                          "margin-bottom": "20px"
                        }
                      },
                      [_vm._v("个人信息")]
                    ),
                    _c(
                      "div",
                      { staticClass: "edit-form" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "证件号码：",
                              "label-width": "110px",
                              prop: "cardNo"
                            }
                          },
                          [
                            _c(
                              "search-input",
                              {
                                key: _vm.keyDate,
                                ref: "searchInputRef",
                                attrs: {
                                  ifDelIcon: _vm.ifDelIcon,
                                  ifSearchInputDisabled:
                                    _vm.ifSearchInputDisabled
                                },
                                on: {
                                  clickSearchIcon: _vm.clickSearchIcon,
                                  clickDelIcon: _vm.clickDelIcon
                                },
                                model: {
                                  value:
                                    _vm.themeActivityListAddDialogForm.cardNo,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.themeActivityListAddDialogForm,
                                      "cardNo",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "themeActivityListAddDialogForm.cardNo"
                                }
                              },
                              _vm._l(_vm.trainingTraineeList, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "idc-item-box",
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseItem(item)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex-row idc-name" },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(item.cardNo)
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "12px"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.userName))]
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "flex-row" }, [
                                      _c("div", { staticClass: "unit-name" }, [
                                        _vm._v(
                                          _vm._s(
                                            item.tdTrainingUnitExp.unitName
                                          )
                                        )
                                      ]),
                                      _c("div", { staticClass: "role-text" }, [
                                        _vm._v(_vm._s(item.roleTypeName))
                                      ])
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "edit-form" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "姓名：",
                              "label-width": "110px",
                              prop: "userName"
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "zwx-input",
                              staticStyle: { width: "160px !important" },
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "50",
                                clearable: "",
                                disabled: false
                              },
                              model: {
                                value:
                                  _vm.themeActivityListAddDialogForm.userName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.themeActivityListAddDialogForm,
                                    "userName",
                                    $$v
                                  )
                                },
                                expression:
                                  "themeActivityListAddDialogForm.userName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "证件类型：",
                              "label-width": "82px",
                              prop: "cardType"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "zwx-select role-type-select",
                                attrs: {
                                  "popper-class": "zwx-select-popper",
                                  placeholder: "请选择",
                                  disabled: _vm.formDisabled
                                },
                                model: {
                                  value:
                                    _vm.themeActivityListAddDialogForm.cardType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.themeActivityListAddDialogForm,
                                      "cardType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "themeActivityListAddDialogForm.cardType"
                                }
                              },
                              _vm._l(_vm.idTypeList, function(item) {
                                return _c("el-option", {
                                  key: item.codeNo,
                                  attrs: {
                                    label: item.codeName,
                                    value: item.codeNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "edit-form" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "手机号：",
                              "label-width": "110px",
                              prop: "mobileTel"
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "zwx-input",
                              staticStyle: { width: "160px !important" },
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "18",
                                clearable: "",
                                disabled: false
                              },
                              model: {
                                value:
                                  _vm.themeActivityListAddDialogForm.mobileTel,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.themeActivityListAddDialogForm,
                                    "mobileTel",
                                    $$v
                                  )
                                },
                                expression:
                                  "themeActivityListAddDialogForm.mobileTel"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "学员类别：",
                              "label-width": "82px",
                              prop: "roleType"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "zwx-select role-type-select",
                                attrs: {
                                  "popper-class": "zwx-select-popper",
                                  placeholder: "请选择",
                                  clearable: "",
                                  disabled: false
                                },
                                on: { change: _vm.resetCourseInfo },
                                model: {
                                  value:
                                    _vm.themeActivityListAddDialogForm.roleType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.themeActivityListAddDialogForm,
                                      "roleType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "themeActivityListAddDialogForm.roleType"
                                }
                              },
                              _vm._l(_vm.targetPopulationOptions, function(
                                item
                              ) {
                                return _c("el-option", {
                                  key: item.codeNo,
                                  attrs: {
                                    label: item.codeName,
                                    value: item.codeNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "edit-form" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "登录账号：",
                              "label-width": "110px",
                              prop: "userNo"
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "zwx-input",
                              staticStyle: { width: "160px !important" },
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "30",
                                clearable: "",
                                disabled: false
                              },
                              model: {
                                value:
                                  _vm.themeActivityListAddDialogForm.userNo,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.themeActivityListAddDialogForm,
                                    "userNo",
                                    $$v
                                  )
                                },
                                expression:
                                  "themeActivityListAddDialogForm.userNo"
                              }
                            })
                          ],
                          1
                        ),
                        !_vm.ifDelIcon
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "登录密码：",
                                  "label-width": "82px",
                                  prop: "initialPassword"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticClass: "zwx-input",
                                    staticStyle: { width: "200px !important" },
                                    attrs: {
                                      placeholder: "请输入",
                                      type: _vm.newView ? "password" : null,
                                      maxlength: "50"
                                    },
                                    model: {
                                      value:
                                        _vm.themeActivityListAddDialogForm
                                          .initialPassword,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.themeActivityListAddDialogForm,
                                          "initialPassword",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "themeActivityListAddDialogForm.initialPassword"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      class: _vm.newView
                                        ? "el-input__icon  outline chis-icon-outline-eye"
                                        : "el-input__icon outline chis-icon-outline-eye-invisible",
                                      attrs: { slot: "suffix" },
                                      on: {
                                        click: function($event) {
                                          _vm.newView = !_vm.newView
                                        }
                                      },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "zwx-circle-text zwx-circle-text:before zwx-circle-normal zwx-circle-normal:before",
                        staticStyle: {
                          color: "#505b89 !important",
                          "margin-bottom": "20px"
                        }
                      },
                      [_vm._v("单位信息")]
                    ),
                    _c(
                      "div",
                      { staticClass: "edit-form" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "所属单位：",
                              "label-width": "110px",
                              prop: "unitName"
                            }
                          },
                          [
                            _c("organ-info-select", {
                              ref: "organInfoSelectRef",
                              attrs: {
                                width: "460px",
                                ifShowConfirmDialog: false,
                                options:
                                  _vm.themeActivityListAddDialogFormExp
                                    .searchUnitList,
                                searchVisible:
                                  _vm.themeActivityListAddDialogFormExp
                                    .unitNameSearchVisible,
                                ifShowMoreBtn: true,
                                disabled: false
                              },
                              on: {
                                unitNameSearchOrDel: _vm.unitNameSearchOrDel,
                                change: _vm.unitNameSelect
                              },
                              model: {
                                value:
                                  _vm.themeActivityListAddDialogForm.unitName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.themeActivityListAddDialogForm,
                                    "unitName",
                                    $$v
                                  )
                                },
                                expression:
                                  "themeActivityListAddDialogForm.unitName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "edit-form flex-row" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "110px",
                                  label: "社会信用代码：",
                                  prop: "creditCode"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticClass: "zwx-input",
                                  staticStyle: { width: "460px !important" },
                                  attrs: {
                                    placeholder: "请输入",
                                    clearable: ""
                                  },
                                  model: {
                                    value:
                                      _vm.themeActivityListAddDialogForm
                                        .creditCode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.themeActivityListAddDialogForm,
                                        "creditCode",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "themeActivityListAddDialogForm.creditCode"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "edit-form flex-row" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "所属地区：",
                                  "label-width": "110px",
                                  prop: "busZoneCode"
                                }
                              },
                              [
                                _c("zwx-select-area", {
                                  ref: "bizZoneArea",
                                  staticClass: "cascader-area-biz-zone",
                                  attrs: {
                                    zoneCode12From: _vm.zoneCode12From,
                                    zoneTypeMax: "0",
                                    zoneTypeMin: "4",
                                    showFullName: false
                                  },
                                  on: { change: _vm.bizZoneChange }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "zwx-circle-text zwx-circle-text:before zwx-circle-normal zwx-circle-normal:before",
                        staticStyle: {
                          color: "#505b89 !important",
                          "margin-bottom": "20px"
                        }
                      },
                      [_vm._v("课程信息")]
                    ),
                    _c(
                      "div",
                      { staticClass: "edit-form" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "课程名称：",
                              "label-width": "110px",
                              prop: "courseUid"
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "zwx-input",
                              staticStyle: { width: "460px !important" },
                              attrs: {
                                placeholder: "",
                                maxlength: "100",
                                "suffix-icon": "el-icon-search"
                              },
                              on: {
                                focus: function($event) {
                                  return _vm.getMsDialog(
                                    _vm.themeActivityListAddDialogForm
                                  )
                                }
                              },
                              model: {
                                value:
                                  _vm.themeActivityListAddDialogForm.courseName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.themeActivityListAddDialogForm,
                                    "courseName",
                                    $$v
                                  )
                                },
                                expression:
                                  "themeActivityListAddDialogForm.courseName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
            ]
          ),
          _c(
            "div",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  staticStyle: { "margin-right": "12px" },
                  attrs: { icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.determineAddDialog(1)
                    }
                  }
                },
                [_vm._v("连续添加")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "coursewareoneDialogRef",
          staticClass: "coursewareone-dialog",
          attrs: {
            title: "添加",
            total: _vm.trainingKaoshiFormExp.materialTotal
          },
          on: {
            determine: _vm.coursewareoneDialogSubmit,
            cancel: _vm.coursewareoneDialogCancel,
            close: _vm.coursewareoneDialogClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "trainingKaoshiFormExp",
              staticClass: "zwx-form",
              staticStyle: { height: "50px !important" },
              attrs: { model: _vm.trainingKaoshiFormExp }
            },
            [
              _c(
                "div",
                { staticClass: "edit-form" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "课程名称：", "label-width": "70px" } },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input cc-input",
                        staticStyle: {
                          cursor: "pointer",
                          "min-width": "30px !important",
                          width: "295px !important"
                        },
                        attrs: {
                          placeholder: "输入查询",
                          maxlength: "200",
                          clearable: ""
                        },
                        model: {
                          value: _vm.trainingKaoshiFormExp.materialNameLike,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.trainingKaoshiFormExp,
                              "materialNameLike",
                              $$v
                            )
                          },
                          expression: "trainingKaoshiFormExp.materialNameLike"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "zwx-button zwx-button-icontext-28",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: {
                        click: function($event) {
                          return _vm.getTeachingMaterialList(1)
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-table",
            {
              ref: "teachingMaterialTable",
              staticClass: "zwx-table",
              attrs: {
                "row-key": "uuid",
                data: _vm.trainingKaoshiFormExp.teachingCourseList,
                border: "",
                stripe: "",
                "tooltip-effect": "light",
                "max-height": "310px"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "选择",
                  width: "80",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-radio", {
                          staticClass: "zwx-radio-course",
                          staticStyle: {
                            "margin-right": "unset !important",
                            width: "15px"
                          },
                          attrs: { label: scope.row.uuid },
                          on: {
                            change: function($event) {
                              return _vm.handleCoursewareSelectionChange(
                                scope.row
                              )
                            }
                          },
                          model: {
                            value: _vm.themeActivityListAddDialogForm.courseUid,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.themeActivityListAddDialogForm,
                                "courseUid",
                                $$v
                              )
                            },
                            expression:
                              "themeActivityListAddDialogForm.courseUid"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "courseName",
                  label: "课程名称",
                  "min-width": "550",
                  "header-align": "center",
                  align: "left",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "onLineClassHoure",
                  label: "学时要求",
                  "header-align": "center",
                  align: "center",
                  width: "100"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("import-file", {
        ref: "importFile",
        on: { success: _vm.exportSuccess }
      }),
      _c(
        "el-dialog",
        {
          staticClass: "bindQrCodeDialog",
          attrs: { title: "签到码", visible: _vm.signInQrCodeVisible },
          on: {
            "update:visible": function($event) {
              _vm.signInQrCodeVisible = $event
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "260px", margin: "10px 40px 0px" },
            attrs: { src: _vm.file + _vm.bindQrCodePath }
          }),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "right",
                padding: "0 20px 10px"
              }
            },
            [
              _c("el-button", { on: { click: _vm.downloadSignInQrCode } }, [
                _vm._v("下载")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "title-icon" }),
      _c("span", { staticClass: "title-txt" }, [_vm._v("主办单位：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "title-icon" }),
      _c("span", { staticClass: "title-txt" }, [_vm._v("开班最少人数：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "title-icon" }),
      _c("span", { staticClass: "title-txt" }, [_vm._v("报名时间：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "title-icon" }),
      _c("span", { staticClass: "title-txt" }, [_vm._v("最大报名人数：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "title-icon" }),
      _c("span", { staticClass: "title-txt" }, [_vm._v("线上课程有效期：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "title-icon" }),
      _c("span", { staticClass: "title-txt" }, [_vm._v("线下培训时间：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "title-icon" }),
      _c("span", { staticClass: "title-txt" }, [_vm._v("线下培训地点：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "title-icon" }),
      _c("span", { staticClass: "title-txt" }, [_vm._v("考试地点：")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }