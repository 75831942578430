var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "organ-info-select" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            "popper-class": "unit-search-popover",
            placement: "bottom-start",
            trigger: _vm.unitNameSearchVisible ? "manual" : null
          },
          model: {
            value: _vm.unitNameSearchVisible,
            callback: function($$v) {
              _vm.unitNameSearchVisible = $$v
            },
            expression: "unitNameSearchVisible"
          }
        },
        [
          _c(
            "el-main",
            { staticClass: "unit-search-content" },
            [
              _c(
                "zwx-scrollbar-y",
                { ref: "searchScrollbar", staticStyle: { height: "100%" } },
                [
                  _vm._l(_vm.options, function(item, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        {
                          staticClass: "drop-down-item",
                          on: {
                            click: function($event) {
                              return _vm.unitNameSelect(item)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "unit-name" }, [
                            _vm._v(" " + _vm._s(item.unitName) + " ")
                          ]),
                          _c("div", [
                            item.legalPerson
                              ? _c("span", { staticClass: "label" }, [
                                  _vm._v("法人：")
                                ])
                              : _vm._e(),
                            item.legalPerson
                              ? _c("span", [_vm._v(_vm._s(item.legalPerson))])
                              : _vm._e(),
                            item.legalPerson
                              ? _c("div", { staticClass: "separator" })
                              : _vm._e(),
                            _c("span", { staticClass: "label" }, [
                              _vm._v("社会信用代码：")
                            ]),
                            _c("span", [_vm._v(_vm._s(item.creditCode))])
                          ])
                        ]
                      )
                    ])
                  }),
                  _vm.ifShowMoreBtn
                    ? _c(
                        "div",
                        {
                          staticClass: "drop-down-item item-more",
                          staticStyle: { "padding-left": "12px" },
                          on: {
                            click: function($event) {
                              return _vm.openUnitNameSearchDialog(_vm.unitName)
                            }
                          }
                        },
                        [_vm._v("更多选择")]
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-input",
            {
              staticClass: "zwx-input ms-unit-input",
              style: `width:${_vm.width}!important`,
              attrs: {
                slot: "reference",
                placeholder: _vm.placeholderText,
                maxlength: "100"
              },
              slot: "reference",
              model: {
                value: _vm.unitName,
                callback: function($$v) {
                  _vm.unitName = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "unitName"
              }
            },
            [
              _vm.opType === "add" && _vm.ifIconShow
                ? _c("el-link", {
                    staticStyle: { "margin-right": "5px !important" },
                    attrs: {
                      slot: "suffix",
                      underline: false,
                      type: "primary",
                      icon: _vm.searchOrDelIcon
                    },
                    on: {
                      click: function($event) {
                        return _vm.unitNameSearchOrDel(_vm.unitName)
                      }
                    },
                    slot: "suffix"
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "msUnitSearchDialog",
          staticClass: "ms-unit-search-dialog",
          attrs: {
            appendToBody: true,
            title: "单位信息",
            pagination: true,
            parentPage: _vm.unitSearchDialog.msUnitSearchDialogForm.currentPage,
            pageSize: _vm.unitSearchDialog.pageSize,
            total: _vm.unitSearchDialog.total
          },
          on: {
            determine: function($event) {
              return _vm.unitSearchDialogDetermine()
            },
            currentPage: _vm.unitInfoDialogSearchInquire,
            cancel: _vm.cancelUnitSearchDialog
          }
        },
        [
          _c(
            "el-form",
            {
              key: "msUnitSearchDialogForm",
              ref: "msUnitSearchDialogForm",
              staticClass: "zwx-form",
              attrs: {
                model: _vm.unitSearchDialog.msUnitSearchDialogForm,
                "label-position": "right"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "单位名称：",
                        prop: "unitName",
                        "label-width": "70px"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "200px" },
                        attrs: { clearable: "" },
                        model: {
                          value:
                            _vm.unitSearchDialog.msUnitSearchDialogForm
                              .unitName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.unitSearchDialog.msUnitSearchDialogForm,
                              "unitName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "unitSearchDialog.msUnitSearchDialogForm.unitName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "社会信用代码：",
                        prop: "creditCode",
                        "label-width": "98px"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "200px" },
                        attrs: { clearable: "" },
                        model: {
                          value:
                            _vm.unitSearchDialog.msUnitSearchDialogForm
                              .creditCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.unitSearchDialog.msUnitSearchDialogForm,
                              "creditCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "unitSearchDialog.msUnitSearchDialogForm.creditCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "zwx-button zwx-button-icontext-28",
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.unitInfoDialogSearch }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-table",
                {
                  key: "rid",
                  staticClass: "zwx-table",
                  attrs: {
                    data: _vm.unitSearchDialog.tablePageList,
                    "tooltip-effect": "light",
                    "highlight-current-row": "",
                    stripe: "",
                    border: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "选择",
                      width: "80",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-radio", {
                              staticClass: "zwx-radio-organInfo",
                              staticStyle: {
                                "margin-right": "unset !important",
                                width: "15px"
                              },
                              attrs: { label: scope.row },
                              model: {
                                value: _vm.unitSearchDialog.unitinfo,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.unitSearchDialog,
                                    "unitinfo",
                                    $$v
                                  )
                                },
                                expression: "unitSearchDialog.unitinfo"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "unitName",
                      label: "单位名称",
                      "min-width": "200",
                      "header-align": "center",
                      align: "left",
                      "show-overflow-tooltip": "",
                      resizable: false
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creditCode",
                      label: "社会信用代码",
                      "min-width": "140",
                      "header-align": "center",
                      align: "center",
                      "show-overflow-tooltip": "",
                      resizable: false
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "legalPerson",
                      label: "法人",
                      "min-width": "140",
                      "header-align": "center",
                      align: "center",
                      "show-overflow-tooltip": "",
                      resizable: false
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }