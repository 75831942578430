<template>
  <div class="pe-record-detail">
    <div class="pe-record-detail-left-box" ref="scrollRef" style="overflow:auto">
      <!-- <zwx-scrollbar-y style="height: 100%;" ref="scrollbar"> -->
      <div id="scrollBox">
        <div id="staffBasicinfoId">
          <base-headline class="no-top" title="基本信息" />
          <div class="staff-basicinfo">
            <div class="staff-info-column">
              <div class="staff-info-row">
                <span class="staff-info-label" style="min-width: 42px;">姓名：</span>
                <span class="staff-info-value">{{ peRecordDetailForm.staffName }}</span>
              </div>
              <div class="staff-info-row">
                <span class="staff-info-label">证件号码：</span>
                <span class="staff-info-value">{{ peRecordDetailForm.desensitizationCardNo }}</span>
              </div>
              <div class="staff-info-row">
                <span class="staff-info-label">部门 (车间) ：</span>
                <span class="staff-info-value">{{ peRecordDetailForm.deptName }}</span>
              </div>
              <div class="staff-info-row">
                <span class="staff-info-label">岗位 (工种) ：</span>
                <span class="staff-info-value">{{ peRecordDetailForm.stationName }}</span>
              </div>
              <div class="staff-info-row" style="margin-bottom:10px">
                <span class="staff-info-label" style="line-height:20px;align-self:flex-start">体检类型：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peTypeFormatter(peRecordDetailForm.peType) }}</span>
              </div>
            </div>
            <div class="staff-info-split-line"></div>
            <div class="staff-info-column">
              <div class="staff-info-row">
                <span class="staff-info-label">体检编号：</span>
                <span class="staff-info-value">{{ peRecordDetailForm.peCode || '--' }}</span>
              </div>
              <div class="staff-info-row">
                <span class="staff-info-label">性别：</span>
                <span class="staff-info-value">{{ peRecordDetailForm.sex || '--' }}</span>
              </div>
              <div class="staff-info-row">
                <span class="staff-info-label">体检日期：</span>
                <span class="staff-info-value">{{ $system.formatDate(peRecordDetailForm.peDate, 'YYYY-MM-DD') }}</span>
              </div>
              <div class="staff-info-row">
                <span class="staff-info-label">体检机构：</span>
                <span class="staff-info-value">{{ peRecordDetailForm.organName }}</span>
              </div>
            </div>
            <div class="staff-info-split-line"></div>
            <div class="staff-info-column">
              <div class="staff-info-row">
                <span class="staff-info-label">在岗状态：</span>
                <span class="staff-info-value">{{ peRecordDetailForm.postState }}</span>
              </div>
              <div class="staff-info-row">
                <span class="staff-info-label">年龄：</span>
                <span class="staff-info-value">{{ peRecordDetailForm.age }}岁</span>
              </div>
              <div class="staff-info-row">
                <span class="staff-info-label">总工龄：</span>
                <span class="staff-info-value" v-show="$zwxBase.verifyIsNotBlank(peRecordDetailForm.workingYear)">{{ peRecordDetailForm.workingYear }}年</span>
                <span class="staff-info-value" v-show="$zwxBase.verifyIsNotBlank(peRecordDetailForm.workingMonth)">{{ peRecordDetailForm.workingMonth }}月</span>
              </div>
              <div class="staff-info-row">
                <span class="staff-info-label">接害工龄：</span>
                <span class="staff-info-value" v-show="$zwxBase.verifyIsNotBlank(peRecordDetailForm.exposureWorkingYear)">{{ peRecordDetailForm.exposureWorkingYear }}年</span>
                <span class="staff-info-value" v-show="$zwxBase.verifyIsNotBlank(peRecordDetailForm.exposureWorkingMonth)">{{ peRecordDetailForm.exposureWorkingMonth }}月</span>
              </div>
            </div>
          </div>
          <el-divider style="margin-left: 16px;margin-bottom: 72px"></el-divider>
          <div class="staff-pe-info">
            <div class="staff-info-row" style="margin-bottom:10px">
              <span class="staff-info-label" style="line-height:20px;align-self:flex-start">危害因素：</span>
              <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.hazardName }}</span>
            </div>
            <div class="staff-info-row" style="margin-bottom:10px">
              <span class="staff-info-label" style="line-height:20px;align-self:flex-start">体检结果：</span>
              <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.summaryResult }}</span>
            </div>
            <div class="staff-info-row" style="margin-bottom:10px">
              <span class="staff-info-label" style="line-height:20px;align-self:flex-start">主检结论：</span>
              <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.mainInspectionConclusionStr }}</span>
            </div>
            <div class="staff-info-row" style="margin-bottom:10px">
              <span class="staff-info-label" style="line-height:20px;align-self:flex-start">主检建议：</span>
              <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.mainInspectionAdvice }}</span>
            </div>
          </div>
        </div>
        <div id="recordInquiryId" v-if="$zwxBase.verifyIsNotBlank(this.peRecordDetailForm.summaryId)">
          <base-headline title="问诊情况" />
          <div class="record-inquiry">
            <div style="padding:20px 20px 0px 6px;">
              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.occupationalHistory)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">职业史：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.occupationalHistory }}</span>
              </div>
              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.radioactiveOccupationalHistory)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">放射职业史：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.radioactiveOccupationalHistory }}</span>
              </div>
              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.anamnesisText)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">既往病史：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.anamnesisText }}</span>
              </div>
              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.symptomText)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">自觉症状：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.symptomText }}</span>
              </div>
              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.menstrualText)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">月经史：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.menstrualText }}</span>
              </div>
              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.maritalText)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">婚姻史：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.maritalText }}</span>
              </div>
              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.childbearingText)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">生育史：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.childbearingText }}</span>
              </div>

              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.smokingStr)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">烟酒史-吸烟情况：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.smokingStr }}</span>
              </div>
              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.drinkingStr)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">烟酒史-饮酒情况：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.drinkingStr }}</span>
              </div>
              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.familyHistory)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">家族史：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.familyHistory }}</span>
              </div>
              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.personalHistory)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">个人史：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.personalHistory }}</span>
              </div>
              <div class="staff-info-row" style="margin-bottom:10px" v-if="$zwxBase.verifyIsNotBlank(peRecordDetailForm.otherHealthConditions)">
                <span class="staff-info-label-2" style="line-height:20px;align-self:flex-start">其他健康状况：</span>
                <span class="staff-info-value" style="line-height:20px">{{ peRecordDetailForm.otherHealthConditions }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(item, index) in peResultDetailList" :key="index">
          <div :id="item.rid">
            <base-headline :title="item.itemCategoryName" />
            <div class="record-inquiry" style="padding: 0 15px 14px 14px;" v-for="(child, indexx) in item.childrenList" :key="indexx">
              <div class="pe-record-detail-subtitle">{{ child.itemCategoryName }}</div>
              <div style="border-left: 1px solid #E1E5F4">
                <el-table ref="itemTable" class="zwx-table" row-key="rid" border stripe :data="child.childrenList" tooltip-effect="light">
                  <el-table-column prop="itemName" label="项目" min-width="120" header-align="center" align="left" />
                  <el-table-column prop="result" label="结果" min-width="200" header-align="center" align="center">
                    <template slot-scope="scope">
                      <span :style="Number(scope.row.result) < Number(scope.row.minValue) ? 'color:#0045F9' : Number(scope.row.result) > Number(scope.row.maxValue) ? 'color:#e72b00' : ''">{{ scope.row.result || '--' }}</span>
                      <span v-if="Number(scope.row.result) < Number(scope.row.minValue)" style="color:#0045F9;font-weight: 600;">↓</span>
                      <span v-if="Number(scope.row.result) > Number(scope.row.maxValue)" style="color:#e72b00;font-weight: 600;">↑</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="referenceValue" label="参考值" width="240" header-align="center" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.referenceValue || '--' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="measurementUnitCode" label="计量单位" width="120" header-align="center" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.measurementUnitCode || '--' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="qualificationEvaluation" label="合格" width="80" header-align="center" align="center">
                    <template slot-scope="scope">
                      <span :style="scope.row.qualificationEvaluation == false ? 'color: #FF2828' : ''">{{ scope.row.qualificationEvaluation == true ? '合格' : scope.row.qualificationEvaluation == false ? '不合格' : '--' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="lacunaMark" label="未检" header-align="left" align="center" width="80">
                    <template slot-scope="scope">
                      <span>{{ scope.row.lacunaMark == true ? '是' : scope.row.lacunaMark == false ? '否' : '--' }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navigation-box">
      <div v-for="(item, index) in peRecordDetailFormExp.navigationTitleList" :key="index">
        <div class="navigation-item" @click="anchor(item)">
          <div class="navigation-item-check-tag" v-show="peRecordDetailFormExp.navigationId == item.id"></div>
          <div class="navigation-item-title" :style="peRecordDetailFormExp.navigationId == item.id ? 'color: #194DFF' : ''">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PeRecordDetail',
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      rid: this.$route.params.rid,
      employerId: this.$route.params.employerId,
      peRecordDetailForm: {},
      peRecordDetailFormExp: {
        navigationTitleList: [{ id: 'staffBasicinfoId', title: '基本信息' }],
        navigationId: 'staffBasicinfoId',
      },
      peResultDetailList: [],

      // 各标题div的高度数组
      heightList: [],
      // 高度和数组
      heightTotalList: [],
      // 滚动高度
      scroll: '',
    }
  },
  created() {
    let type = this.$route.params.type
    if (type === 'outJump') {
      this.$emit('breadcrumb', '体检详情', true)
    }
  },
  mounted() {
    this.getPeRecordDetail()
    window.addEventListener('scroll', this.handleScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    handleScroll() {
      this.scroll = this.$refs.scrollRef.scrollTop + 70
      // 获取可视区的高度
      var windowHeight = this.$refs.scrollRef.clientHeight
      // 获取滚动条的总高度
      var scrollHeight = this.$refs.scrollRef.scrollHeight
      let CurrentIndex = this.heightTotalList.findIndex(item => item > this.scroll)
      this.peRecordDetailFormExp.navigationTitleList.map((item, i) => {
        if (CurrentIndex == i) {
          this.peRecordDetailFormExp.navigationId = item.id
        }
      })
      if (this.scroll + windowHeight + 500 >= scrollHeight) {
        this.peRecordDetailFormExp.navigationId = this.peRecordDetailFormExp.navigationTitleList[this.peRecordDetailFormExp.navigationTitleList.length - 1].id
      }
      if (this.scroll == 0) {
        this.peRecordDetailFormExp.navigationId = 'staffBasicinfoId'
      }
    },
    onScroll() {
      this.peRecordDetailFormExp.navigationTitleList.map(item => {
        var div = document.getElementById(item.id)
        this.heightList.push(div.offsetHeight)
      })
      this.heightList.reduce((prev, cur) => {
        this.heightTotalList.push(prev)
        return prev + cur
      })
    },
    /**
     * 获取体检记录详情
     */
    getPeRecordDetail() {
      let data = {
        uuid: this.rid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/health/surveillance/getPeRecord-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.peRecordDetailForm = data.peRecord
            if (this.$zwxBase.verifyIsNotBlank(this.peRecordDetailForm.summaryId)) {
              this.peRecordDetailFormExp.navigationTitleList.push({
                id: 'recordInquiryId',
                title: '问诊情况',
              })
            }
            this.peResultDetailList = data.peRecord.peResultDetailList || []
            this.peResultDetailList.map(item => {
              this.peRecordDetailFormExp.navigationTitleList.push({
                id: item.rid,
                title: item.itemCategoryName,
              })
            })
            // this.sm4tdStaffInfoList(this.msForm)
            this.$nextTick(() => {
              this.onScroll()
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
          this.$emit('loading', false)
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
    },
    /**
     * 锚点导航点击事件
     */
    anchor(row) {
      document.getElementById(row.id).scrollIntoView({
        // behavior: 'smooth', // 平滑过渡
        // block: 'start',
      })
      this.peRecordDetailFormExp.navigationId = row.id
    },
    /**
     * 在职状态格式化
     */
    dutyStatusFormatter(value) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        switch (value) {
          case 1:
            return '在职'
          case 2:
            return '离职'
          default:
        }
      }
      return ''
    },
    /**
     * 体检类型格式化
     */
    peTypeFormatter(value) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        switch (value) {
          case 1:
            return '职业健康体检'
          case 2:
            return '放射人员健康检查'
          case 3:
            return '福利体检'
          default:
        }
      }
      return '-'
    },
    /**
     * 吸烟情况格式化
     */
    smokingFomatter(value) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        switch (value) {
          case 0:
            return '不吸烟'
          case 1:
            return '偶尔吸'
          case 2:
            return '经常吸'
          default:
        }
      }
      return '/'
    },
    /**
     * 饮酒情况格式化
     */
    drinkingFomatter(value) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        switch (value) {
          case 0:
            return '不饮酒'
          case 1:
            return '偶尔饮'
          case 2:
            return '经常饮'
          default:
        }
      }
      return '/'
    },
  },
}
</script>

<style lang="less" scoped>
.pe-record-detail {
  display: flex;

  .pe-record-detail-left-box {
    width: calc(100% - 190px);
    height: calc(100vh - 43px);
  }

  .pe-record-detail-left-box::-webkit-scrollbar {
    display: none;
  }

  .navigation-box {
    display: flex;
    flex-direction: column;
    min-width: 190px;
    height: calc(100vh - 43px);
    border-left: 1px solid #e1e5f4;
    padding-top: 18px;

    .navigation-item {
      display: flex;
      align-items: center;
      height: 26px;
      margin-bottom: 20px;
      cursor: pointer;
    }

    .navigation-item-check-tag {
      position: absolute;
      width: 3px;
      height: 26px;
      background: #5574df;
    }

    .navigation-item-title {
      font-size: 14px;
      color: #191919;
      line-height: 14px;
      padding-left: 11px;
    }
  }

  .staff-basicinfo {
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .staff-info-split-line {
    border-left: 1px solid #d4daf0;
    height: 140px;
  }

  .staff-info-column {
    min-width: 126px;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  .staff-info-row {
    min-width: 243px;
    min-height: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .staff-info-row:last-child {
    margin-bottom: unset;
  }

  .staff-info-label {
    min-width: 70px;
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #3a485e;
    line-height: 14px;
  }

  .staff-info-label-2 {
    min-width: 40px;
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #3a485e;
    line-height: 14px;
  }

  .staff-info-value {
    min-height: 14px;
    font-size: 14px;
    color: #191919;
    line-height: 14px;
  }

  /deep/ .el-divider--horizontal {
    margin: 0 !important;
  }

  .staff-pe-info {
    padding: 16px;
  }

  .record-inquiry {
    padding: 0 0 14px 14px;
  }

  .pe-record-detail-subtitle {
    height: 14px;
    font-size: 14px;
    text-align: left;
    color: #505b89;
    line-height: 14px;
    margin: 12px 0;
    padding-left: 2px;
    font-family: 'Harmony Medium';
  }

  .record-inquiry-row {
    display: flex;
  }

  .record-inquiry-column {
    width: 558px;
    margin-right: 27px;
  }

  .record-inquiry-column:last-child {
    margin-right: unset;
  }

  .record-inquiry-box {
    border-left: 1px solid #d5d9e0;
    border-top: 1px solid #d5d9e0;

    &-row {
      display: flex;
    }

    &-column-label {
      min-width: 120px;
      height: 38px;
      font-size: 14px;
      text-align: right;
      color: #3a485e;
      line-height: 38px;
      border-right: 1px solid #d5d9e0;
      border-bottom: 1px solid #d5d9e0;
    }

    &-column-value {
      min-width: 158px;
      height: 38px;
      font-size: 14px;
      color: #191919;
      line-height: 38px;
      border-right: 1px solid #d5d9e0;
      border-bottom: 1px solid #d5d9e0;
      padding-left: 7px;
    }
  }
}
</style>

<style lang="less"></style>
