var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("import-dialog", {
    ref: "importDialogRef",
    style: {
      fontWeight: "normal"
    },
    attrs: {
      title: "批量导入学员",
      ifPass: false,
      accept: ".xls,.xlsx,.XLS,.XLSX",
      "ifshow-upload": _vm.$zwxBase.verifyIsBlank(
        _vm.staffinfoImportDialog.annexName
      ),
      size: 5 * 1024 * 1024,
      sizeInfo: "5M",
      action: _vm.api + "/training/platform/importTrainingList-0",
      paramData: _vm.staffinfoImportDialog.paramData,
      errorName: _vm.staffinfoImportDialog.errorName,
      errorPath: _vm.staffinfoImportDialog.errorPath,
      errorMsg: _vm.staffinfoImportDialog.errorMsg
    },
    on: {
      beforeUpload: _vm.fileBeforeUpload,
      tempDownload: _vm.tempDownload,
      deletionFile: _vm.deletionFile,
      fileSubmitSuccess: _vm.fileSubmitSuccess,
      fileSubmitError: _vm.fileSubmitError,
      change: _vm.clickFileUpload,
      determine: _vm.staffImportDialogDetermine,
      cancel: _vm.staffImportDialogCancel,
      close: _vm.staffImportDialogClose,
      updateSuccess: _vm.fileSubmitSuccess
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }