var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pe-record-detail" }, [
    _c(
      "div",
      {
        ref: "scrollRef",
        staticClass: "pe-record-detail-left-box",
        staticStyle: { overflow: "auto" }
      },
      [
        _c(
          "div",
          { attrs: { id: "scrollBox" } },
          [
            _c(
              "div",
              { attrs: { id: "staffBasicinfoId" } },
              [
                _c("base-headline", {
                  staticClass: "no-top",
                  attrs: { title: "基本信息" }
                }),
                _c("div", { staticClass: "staff-basicinfo" }, [
                  _c("div", { staticClass: "staff-info-column" }, [
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c(
                        "span",
                        {
                          staticClass: "staff-info-label",
                          staticStyle: { "min-width": "42px" }
                        },
                        [_vm._v("姓名：")]
                      ),
                      _c("span", { staticClass: "staff-info-value" }, [
                        _vm._v(_vm._s(_vm.peRecordDetailForm.staffName))
                      ])
                    ]),
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c("span", { staticClass: "staff-info-label" }, [
                        _vm._v("证件号码：")
                      ]),
                      _c("span", { staticClass: "staff-info-value" }, [
                        _vm._v(
                          _vm._s(_vm.peRecordDetailForm.desensitizationCardNo)
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c("span", { staticClass: "staff-info-label" }, [
                        _vm._v("部门 (车间) ：")
                      ]),
                      _c("span", { staticClass: "staff-info-value" }, [
                        _vm._v(_vm._s(_vm.peRecordDetailForm.deptName))
                      ])
                    ]),
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c("span", { staticClass: "staff-info-label" }, [
                        _vm._v("岗位 (工种) ：")
                      ]),
                      _c("span", { staticClass: "staff-info-value" }, [
                        _vm._v(_vm._s(_vm.peRecordDetailForm.stationName))
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "staff-info-row",
                        staticStyle: { "margin-bottom": "10px" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "staff-info-label",
                            staticStyle: {
                              "line-height": "20px",
                              "align-self": "flex-start"
                            }
                          },
                          [_vm._v("体检类型：")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "staff-info-value",
                            staticStyle: { "line-height": "20px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.peTypeFormatter(
                                  _vm.peRecordDetailForm.peType
                                )
                              )
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "staff-info-split-line" }),
                  _c("div", { staticClass: "staff-info-column" }, [
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c("span", { staticClass: "staff-info-label" }, [
                        _vm._v("体检编号：")
                      ]),
                      _c("span", { staticClass: "staff-info-value" }, [
                        _vm._v(_vm._s(_vm.peRecordDetailForm.peCode || "--"))
                      ])
                    ]),
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c("span", { staticClass: "staff-info-label" }, [
                        _vm._v("性别：")
                      ]),
                      _c("span", { staticClass: "staff-info-value" }, [
                        _vm._v(_vm._s(_vm.peRecordDetailForm.sex || "--"))
                      ])
                    ]),
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c("span", { staticClass: "staff-info-label" }, [
                        _vm._v("体检日期：")
                      ]),
                      _c("span", { staticClass: "staff-info-value" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$system.formatDate(
                              _vm.peRecordDetailForm.peDate,
                              "YYYY-MM-DD"
                            )
                          )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c("span", { staticClass: "staff-info-label" }, [
                        _vm._v("体检机构：")
                      ]),
                      _c("span", { staticClass: "staff-info-value" }, [
                        _vm._v(_vm._s(_vm.peRecordDetailForm.organName))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "staff-info-split-line" }),
                  _c("div", { staticClass: "staff-info-column" }, [
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c("span", { staticClass: "staff-info-label" }, [
                        _vm._v("在岗状态：")
                      ]),
                      _c("span", { staticClass: "staff-info-value" }, [
                        _vm._v(_vm._s(_vm.peRecordDetailForm.postState))
                      ])
                    ]),
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c("span", { staticClass: "staff-info-label" }, [
                        _vm._v("年龄：")
                      ]),
                      _c("span", { staticClass: "staff-info-value" }, [
                        _vm._v(_vm._s(_vm.peRecordDetailForm.age) + "岁")
                      ])
                    ]),
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c("span", { staticClass: "staff-info-label" }, [
                        _vm._v("总工龄：")
                      ]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$zwxBase.verifyIsNotBlank(
                                _vm.peRecordDetailForm.workingYear
                              ),
                              expression:
                                "$zwxBase.verifyIsNotBlank(peRecordDetailForm.workingYear)"
                            }
                          ],
                          staticClass: "staff-info-value"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.peRecordDetailForm.workingYear) + "年"
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$zwxBase.verifyIsNotBlank(
                                _vm.peRecordDetailForm.workingMonth
                              ),
                              expression:
                                "$zwxBase.verifyIsNotBlank(peRecordDetailForm.workingMonth)"
                            }
                          ],
                          staticClass: "staff-info-value"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.peRecordDetailForm.workingMonth) + "月"
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "staff-info-row" }, [
                      _c("span", { staticClass: "staff-info-label" }, [
                        _vm._v("接害工龄：")
                      ]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$zwxBase.verifyIsNotBlank(
                                _vm.peRecordDetailForm.exposureWorkingYear
                              ),
                              expression:
                                "$zwxBase.verifyIsNotBlank(peRecordDetailForm.exposureWorkingYear)"
                            }
                          ],
                          staticClass: "staff-info-value"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.peRecordDetailForm.exposureWorkingYear) +
                              "年"
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$zwxBase.verifyIsNotBlank(
                                _vm.peRecordDetailForm.exposureWorkingMonth
                              ),
                              expression:
                                "$zwxBase.verifyIsNotBlank(peRecordDetailForm.exposureWorkingMonth)"
                            }
                          ],
                          staticClass: "staff-info-value"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.peRecordDetailForm.exposureWorkingMonth
                            ) + "月"
                          )
                        ]
                      )
                    ])
                  ])
                ]),
                _c("el-divider", {
                  staticStyle: {
                    "margin-left": "16px",
                    "margin-bottom": "72px"
                  }
                }),
                _c("div", { staticClass: "staff-pe-info" }, [
                  _c(
                    "div",
                    {
                      staticClass: "staff-info-row",
                      staticStyle: { "margin-bottom": "10px" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "staff-info-label",
                          staticStyle: {
                            "line-height": "20px",
                            "align-self": "flex-start"
                          }
                        },
                        [_vm._v("危害因素：")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "staff-info-value",
                          staticStyle: { "line-height": "20px" }
                        },
                        [_vm._v(_vm._s(_vm.peRecordDetailForm.hazardName))]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "staff-info-row",
                      staticStyle: { "margin-bottom": "10px" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "staff-info-label",
                          staticStyle: {
                            "line-height": "20px",
                            "align-self": "flex-start"
                          }
                        },
                        [_vm._v("体检结果：")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "staff-info-value",
                          staticStyle: { "line-height": "20px" }
                        },
                        [_vm._v(_vm._s(_vm.peRecordDetailForm.summaryResult))]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "staff-info-row",
                      staticStyle: { "margin-bottom": "10px" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "staff-info-label",
                          staticStyle: {
                            "line-height": "20px",
                            "align-self": "flex-start"
                          }
                        },
                        [_vm._v("主检结论：")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "staff-info-value",
                          staticStyle: { "line-height": "20px" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.peRecordDetailForm.mainInspectionConclusionStr
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "staff-info-row",
                      staticStyle: { "margin-bottom": "10px" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "staff-info-label",
                          staticStyle: {
                            "line-height": "20px",
                            "align-self": "flex-start"
                          }
                        },
                        [_vm._v("主检建议：")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "staff-info-value",
                          staticStyle: { "line-height": "20px" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.peRecordDetailForm.mainInspectionAdvice)
                          )
                        ]
                      )
                    ]
                  )
                ])
              ],
              1
            ),
            _vm.$zwxBase.verifyIsNotBlank(this.peRecordDetailForm.summaryId)
              ? _c(
                  "div",
                  { attrs: { id: "recordInquiryId" } },
                  [
                    _c("base-headline", { attrs: { title: "问诊情况" } }),
                    _c("div", { staticClass: "record-inquiry" }, [
                      _c(
                        "div",
                        { staticStyle: { padding: "20px 20px 0px 6px" } },
                        [
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm.occupationalHistory
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("职业史：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm
                                            .occupationalHistory
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm
                              .radioactiveOccupationalHistory
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("放射职业史：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm
                                            .radioactiveOccupationalHistory
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm.anamnesisText
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("既往病史：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm.anamnesisText
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm.symptomText
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("自觉症状：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm.symptomText
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm.menstrualText
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("月经史：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm.menstrualText
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm.maritalText
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("婚姻史：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm.maritalText
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm.childbearingText
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("生育史：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm
                                            .childbearingText
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm.smokingStr
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("烟酒史-吸烟情况：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm.smokingStr
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm.drinkingStr
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("烟酒史-饮酒情况：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm.drinkingStr
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm.familyHistory
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("家族史：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm.familyHistory
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm.personalHistory
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("个人史：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm.personalHistory
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.peRecordDetailForm.otherHealthConditions
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "staff-info-row",
                                  staticStyle: { "margin-bottom": "10px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-label-2",
                                      staticStyle: {
                                        "line-height": "20px",
                                        "align-self": "flex-start"
                                      }
                                    },
                                    [_vm._v("其他健康状况：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "staff-info-value",
                                      staticStyle: { "line-height": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.peRecordDetailForm
                                            .otherHealthConditions
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._l(_vm.peResultDetailList, function(item, index) {
              return _c("div", { key: index }, [
                _c(
                  "div",
                  { attrs: { id: item.rid } },
                  [
                    _c("base-headline", {
                      attrs: { title: item.itemCategoryName }
                    }),
                    _vm._l(item.childrenList, function(child, indexx) {
                      return _c(
                        "div",
                        {
                          key: indexx,
                          staticClass: "record-inquiry",
                          staticStyle: { padding: "0 15px 14px 14px" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pe-record-detail-subtitle" },
                            [_vm._v(_vm._s(child.itemCategoryName))]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "border-left": "1px solid #E1E5F4"
                              }
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "itemTable",
                                  refInFor: true,
                                  staticClass: "zwx-table",
                                  attrs: {
                                    "row-key": "rid",
                                    border: "",
                                    stripe: "",
                                    data: child.childrenList,
                                    "tooltip-effect": "light"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "itemName",
                                      label: "项目",
                                      "min-width": "120",
                                      "header-align": "center",
                                      align: "left"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "result",
                                      label: "结果",
                                      "min-width": "200",
                                      "header-align": "center",
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  style:
                                                    Number(scope.row.result) <
                                                    Number(scope.row.minValue)
                                                      ? "color:#0045F9"
                                                      : Number(
                                                          scope.row.result
                                                        ) >
                                                        Number(
                                                          scope.row.maxValue
                                                        )
                                                      ? "color:#e72b00"
                                                      : ""
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.result || "--"
                                                    )
                                                  )
                                                ]
                                              ),
                                              Number(scope.row.result) <
                                              Number(scope.row.minValue)
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#0045F9",
                                                        "font-weight": "600"
                                                      }
                                                    },
                                                    [_vm._v("↓")]
                                                  )
                                                : _vm._e(),
                                              Number(scope.row.result) >
                                              Number(scope.row.maxValue)
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#e72b00",
                                                        "font-weight": "600"
                                                      }
                                                    },
                                                    [_vm._v("↑")]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "referenceValue",
                                      label: "参考值",
                                      width: "240",
                                      "header-align": "center",
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.referenceValue ||
                                                      "--"
                                                  )
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "measurementUnitCode",
                                      label: "计量单位",
                                      width: "120",
                                      "header-align": "center",
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row
                                                      .measurementUnitCode ||
                                                      "--"
                                                  )
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "qualificationEvaluation",
                                      label: "合格",
                                      width: "80",
                                      "header-align": "center",
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  style:
                                                    scope.row
                                                      .qualificationEvaluation ==
                                                    false
                                                      ? "color: #FF2828"
                                                      : ""
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .qualificationEvaluation ==
                                                        true
                                                        ? "合格"
                                                        : scope.row
                                                            .qualificationEvaluation ==
                                                          false
                                                        ? "不合格"
                                                        : "--"
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "lacunaMark",
                                      label: "未检",
                                      "header-align": "left",
                                      align: "center",
                                      width: "80"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.lacunaMark == true
                                                      ? "是"
                                                      : scope.row.lacunaMark ==
                                                        false
                                                      ? "否"
                                                      : "--"
                                                  )
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ])
            })
          ],
          2
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "navigation-box" },
      _vm._l(_vm.peRecordDetailFormExp.navigationTitleList, function(
        item,
        index
      ) {
        return _c("div", { key: index }, [
          _c(
            "div",
            {
              staticClass: "navigation-item",
              on: {
                click: function($event) {
                  return _vm.anchor(item)
                }
              }
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.peRecordDetailFormExp.navigationId == item.id,
                    expression: "peRecordDetailFormExp.navigationId == item.id"
                  }
                ],
                staticClass: "navigation-item-check-tag"
              }),
              _c(
                "div",
                {
                  staticClass: "navigation-item-title",
                  style:
                    _vm.peRecordDetailFormExp.navigationId == item.id
                      ? "color: #194DFF"
                      : ""
                },
                [_vm._v(_vm._s(item.title))]
              )
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }