var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-input" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-start",
            "popper-class": "search-input-css",
            title: "",
            width: "200",
            trigger: "manual"
          },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _vm._t("default"),
          _c(
            "el-input",
            {
              staticClass: "zwx-input",
              staticStyle: { width: "460px!important" },
              attrs: {
                slot: "reference",
                placeholder: _vm.placeholder,
                maxlength: "100",
                disabled: _vm.ifSearchInputDisabled
              },
              on: { blur: _vm.inputBlur },
              slot: "reference",
              model: {
                value: _vm.inputValue,
                callback: function($$v) {
                  _vm.inputValue = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "inputValue"
              }
            },
            [
              !_vm.ifDelIcon
                ? _c("i", {
                    staticClass: "el-input__icon el-icon-search",
                    staticStyle: { cursor: "pointer" },
                    attrs: { slot: "suffix" },
                    on: { click: _vm.handlerClick },
                    slot: "suffix"
                  })
                : _vm._e(),
              _vm.ifDelIcon
                ? _c("i", {
                    staticClass: "el-input__icon el-icon-delete",
                    staticStyle: { cursor: "pointer" },
                    attrs: { slot: "suffix" },
                    on: { click: _vm.handlerDel },
                    slot: "suffix"
                  })
                : _vm._e()
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }